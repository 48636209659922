<template>
	<div class="supplyGoods">

		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="商品名称、编码、规格条形码" style="width:250px;margin-right: 10px;" clearable
				 @keyup.enter.native="goodsFilter"></el-input>
			</div>

			<div class="filter-item">
				<label class="label">商品分组: </label>
				<el-select v-model="goodsType" placeholder="请选择" style="width: 250px;margin-right: 10px;" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in productSortList" :key="item.Id" :label="item.GroupName" :value="item.Id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<label class="label">商品品牌: </label>
				<el-select v-model="brandType" placeholder="请选择" style="width: 250px;" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in productList" :key="item.Id" :label="item.BrandName" :value="item.Id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<el-button type="primary" @click="goodsFilter">查询</el-button>
			</div>
		</div>

		<div class="table-container">
			<el-table :data="groupData" style="width: 100%;" @sort-change="sortChange" v-loading="loading" ref="multipleTable">

				<el-table-column label="商品" width="450">
					<template slot-scope="scope">
						<div class="product-info" style="align-items:flex-start">
							<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl+'@!cut64'">
							<svg-icon v-else icon-class="noImgIcon" />
							<div style="min-height: auto;">
								<div style="display:flex;justify-content:space-between;align-items:flex-start;">
									<div style="color: #f00;font-size: 12px;width:40px" v-if="scope.row.ProductType==1">[组合]</div>
									<div style="width:300px;">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
									</div>
								</div>
								<div style="color:#909399">{{scope.row.ProductNo}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="DistributorCount" label="分销商家"></el-table-column>
				<el-table-column label="供货价">
					<template slot-scope="scope">
						<div style="color: red;">{{scope.row.SupplierPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="Stock" sortable label="库存"></el-table-column>
				<el-table-column prop="DistributorSellCount" sortable label="分销销量"></el-table-column>
			</el-table>
			<div style="text-align: right;margin-top: 10px;">
				<el-pagination v-if="Total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
				 :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="Total">
				</el-pagination>
			</div>

		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		productBrandList,
		productGroupList,
	} from '@/api/goods'
	import {supplierProductList} from '@/api/TurnTomySelf'
	export default {
		data() {
			return {
				searchKey: '',
				goodsType:null,
				productSortList: [],
				brandType:null,
				productList: [],
				OrderBy: '',
				IsAsc: 0,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				groupData:[],
				loading:false,
				imgUrl: config.IMG_BASE,
			}
		},
		beforeMount() {
			this.getInfor()
			this.getList()
		},
		methods: {
			goodsFilter() {
				this.currentPage = 1
				this.getList();
			},
			//筛选数据
			async getInfor() {
				try {
					let result = await productBrandList()
					this.productList = result.Result

					let result1 = await productGroupList()
					this.productSortList = result1.Result

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
			// 获取商品列表
			async getList() {
				try {
					this.loading = true
					let data = {
						KeyWord:this.searchKey,
						ProductGroupId: this.goodsType,
						ProductBrandId: this.brandType, // 品牌id
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc
					}
					let result = await supplierProductList(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];


				} catch (e) {
					console.log(e)
				} finally {
					setTimeout(() => {
						this.loading = false
					}, 500)

				}

			},
			// 排序
			sortChange(column, prop, order) {
				this.OrderBy = column.prop;
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.currentPage = 1;
				this.getList();
			},

		}
	}
</script>

<style lang="less" scoped>
	.supplyGoods {
		padding: 10px;
		background: #fff;
	}
</style>
